import './app.scss';

import { inview } from './js/inview';
import { swiper } from './js/swiper';
import { isMobileLp, isMobilePt } from './js/utils/checkMedia';

function start() {
    setTimeout(() => {
        const bodyEl = document.querySelector('body');
        const preloaderEl = document.getElementById('preloader');

        preloaderEl.classList.add('hide');
        bodyEl.style.height = '100%';
        bodyEl.style.overflowY = 'auto';

        setTimeout(() => {
            preloaderEl.remove();
        }, 550);
    }, 2500);

    console.log('isMobilePt', isMobilePt());
    console.log('isMobileLp', isMobileLp());

    swiper();

    if (inview) console.log('animate started');
}

start();
