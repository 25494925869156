// device from custom-media.pcss
const width = window.innerWidth;
const height = window.innerHeight;
const orientLandscape = window.matchMedia('(orientation: landscape)').matches;
const orientPortrait = window.matchMedia('(orientation: portrait)').matches;

// --viewport-portrait
export const isDesktop = () => width > 1024 || (width > 430 && width <= 1024 && orientLandscape);
export const isPortrait = () => width <= 1024 && orientPortrait;

// --viewport-mobile-lp
export const isMobileLp = () => height <= 425 && orientLandscape;

// --viewport-mobile-pt
export const isMobilePt = () => width <= 425 && orientPortrait;
