import Swiper, { Navigation } from 'swiper';

export const swiper = () => {
    const swiperEl = new Swiper('.mySwiper', {
        modules: [Navigation],
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev'
        }
    });

    swiperEl();
};
